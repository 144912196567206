// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-charter-page-js": () => import("./../../src/templates/charter-page.js" /* webpackChunkName: "component---src-templates-charter-page-js" */),
  "component---src-templates-checklists-page-js": () => import("./../../src/templates/checklists-page.js" /* webpackChunkName: "component---src-templates-checklists-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-current-reservations-page-js": () => import("./../../src/templates/current-reservations-page.js" /* webpackChunkName: "component---src-templates-current-reservations-page-js" */),
  "component---src-templates-field-research-station-page-js": () => import("./../../src/templates/field-research-station-page.js" /* webpackChunkName: "component---src-templates-field-research-station-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-information-for-scientists-page-js": () => import("./../../src/templates/information-for-scientists-page.js" /* webpackChunkName: "component---src-templates-information-for-scientists-page-js" */),
  "component---src-templates-maps-page-js": () => import("./../../src/templates/maps-page.js" /* webpackChunkName: "component---src-templates-maps-page-js" */),
  "component---src-templates-new-species-page-js": () => import("./../../src/templates/new-species-page.js" /* webpackChunkName: "component---src-templates-new-species-page-js" */),
  "component---src-templates-pay-page-js": () => import("./../../src/templates/pay-page.js" /* webpackChunkName: "component---src-templates-pay-page-js" */),
  "component---src-templates-published-papers-page-js": () => import("./../../src/templates/published-papers-page.js" /* webpackChunkName: "component---src-templates-published-papers-page-js" */),
  "component---src-templates-register-page-js": () => import("./../../src/templates/register-page.js" /* webpackChunkName: "component---src-templates-register-page-js" */),
  "component---src-templates-researchers-and-reports-page-js": () => import("./../../src/templates/researchers-and-reports-page.js" /* webpackChunkName: "component---src-templates-researchers-and-reports-page-js" */),
  "component---src-templates-resources-page-js": () => import("./../../src/templates/resources-page.js" /* webpackChunkName: "component---src-templates-resources-page-js" */),
  "component---src-templates-science-page-js": () => import("./../../src/templates/science-page.js" /* webpackChunkName: "component---src-templates-science-page-js" */),
  "component---src-templates-support-page-js": () => import("./../../src/templates/support-page.js" /* webpackChunkName: "component---src-templates-support-page-js" */)
}

